.homepage {
  .section-why-use-mss {
    .background {
      background-image: url(../../images/homepage/visuel-pq-def.png);
      background-repeat: no-repeat;
      background-position: left center;
      background-size: cover;
    }
  }

  .search-form-app {
    max-width: 850px;
    margin: auto;
    background-color: #ffffff99;
    padding: 16px;
    border-radius: 20px;
  }

  .section-values {
    p.value {
      font-size: 90px;
      line-height: 90px;
      font-weight: bold;
    }

    .value-separator {
      vertical-align: middle;
      font-size: 24px;
      margin-bottom: 0;

      hr {
        border: 1px solid #ffffff;
        width: 65px;
        display: inline-block;
        margin: 8px 10px;
      }
    }

    p.value-category {
      font-size: 24px;
      line-height: 26px;
      font-weight: bold;
    }
  }

  .section-review {
    .carousel {
      .carousel-item {
        height: 380px;

        p {
          max-width: 600px;
          margin: auto;
        }

        img {
          border-radius: 50%;
          height: 100px;
        }
      }
    }
  }

  .sports-wrapper {
    @include media-breakpoint-down(xs) {
      width: calc(100% + 32px);
      position: relative;
      left: -16px;
    }
  }

  a.sport-block {
    display: inline-block;
    background-color: grey;
    height: 90px;
    width: 130px;
    margin: 10px;
    color: #ffffff;
    vertical-align: bottom;
    position: relative;
    background-size: cover;
    background-position: center;

    > div {
      width: 100%;
      height: inherit;
      background-color: rgba(0, 0, 0, 0.2);
      display: flex;
    }

    p {
      width: inherit;
      padding: 0 10px;
      margin: auto;
    }

    @include media-breakpoint-down(xs) {
      width: calc(33% - 8px);
      margin: 4px 3px;
      height: 80px;
    }
  }

  .tags-list > p, .tags-list > a {
    display: inline-block;
    background-color: rgba(255, 255, 255, 0.5);
    padding: 10px 20px;
    border-radius: 43px;
    font-weight: bold;
  }

  @include media-breakpoint-down(sm) {
    .homepage-top-row {
      padding-top: 30px !important;
      padding-bottom: 20px !important;

      .session-search-form {
        margin-top: 30px !important;
        margin-bottom: 30px !important;
      }
    }
  }
}
