.map-maker {
  font-size: 38px;
  position: relative;
  top: -35px;
  left: -12px;

  @-webkit-keyframes clignote {
    0% {
      color: rgb(237, 100, 26);
    }
    50% {
      color: rgba(237, 100, 26, 1);
    }
    100% {
      color: rgba(237, 100, 26, 0.5);
    }
  }

  &.icon-not-hovered {
    font-size: 25px;
    color: $color-mss-red;
    top: -24px;
    left: -9px;
  }

  &.icon-hovered {
    -webkit-animation-name: clignote;
    -webkit-animation-duration: 1s;
    -webkit-animation-iteration-count: infinite;
  }

  &.icon-current-position {
    color: $color-mss-blue;
    top: -15px;
    left: -9px;
  }
}

.leaflet-marker-icon {
  height: 0 !important;
  width: 0 !important;
}
