:not(.input-group-prepend, .input-group-append) > .btn,
.btn-lg,
.btn-group-lg > .btn {
    border-radius: 40px !important;
}

.btn-xs,
.btn-group-xs > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  line-height: 1;
  border-radius: 0.2rem;
}
