#pro-section {
  .advantages {
    img {
      max-height: 200px;
      max-width: 350px;
    }
  }
}

#pro-subsection {
  h2 {
    display: block;
    text-align: center;
    border: solid 1px;
    padding: 25px;
  }

  li {
    list-style-type: none;
    color: $color-mss-blue;
    font-size: 18px;
  }

  ul {
    padding: 0 10px;
  }

  i.fas {
    padding-right: 10px;
  }
}

#pro-section,
#pro-subsection {
  .cgu-container {
    margin-bottom: 30px;

    .form-group {
      margin-bottom: 0;
    }
  }
}
