.search-form-app {
  display: flex;
  justify-content: center;

  .place-autocomplete {
    width: 100%;
    padding-bottom: 10px;

    input {
      width: 100%;
      text-align-last: center;
      text-align: center;
      -ms-text-align-last: center;
      -moz-text-align-last: center;
    }
  }

  .sport-field {
    width: 100%;
    padding-bottom: 10px;
    min-width: 210px;

    select {
      text-align-last: center;
      text-align: center;
      -ms-text-align-last: center;
      -moz-text-align-last: center;
    }

    .rw-popup-container {
      width: max-content;
      right: inherit;
    }
  }

  .date-field {
    width: 100%;
    min-width: 200px;

    select {
      text-align-last: center;
      text-align: center;
      -ms-text-align-last: center;
      -moz-text-align-last: center;
    }

    input {
      text-align: center;
    }
  }

  .time-field {
    width: 50%;
  }

  .btn {
    width: 100%;
    margin-top: 10px;
  }

  @include media-breakpoint-up(sm) {
    .place-autocomplete {
      width: inherit;
      padding-bottom: 0;
      padding-right: 15px;
      flex: 1;
    }

    .sport-field {
      width: inherit;
      padding-bottom: 0;
      padding-right: 15px;
      flex: 1;
      max-width: 210px;
    }

    .date-field {
      max-width: 200px;
      width: inherit;
      padding-right: 15px;
      flex: 1;
    }

    .time-field {
      width: inherit;
      max-width: 210px;
      padding-right: 15px;
      flex: 1;
    }

    .btn {
      width: inherit;
      margin-top: initial;
    }
  }
}
