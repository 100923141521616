.referral-landing-page {
  .section-why-use-mss {
    .background {
      background-image: url(../../images/homepage/visuel-pq-def.png);
      background-repeat: no-repeat;
      background-position: left center;
      background-size: cover;
    }
  }

  a.sport-block {
    display: inline-block;
    background-color: grey;
    height: 90px;
    width: 130px;
    margin: 10px;
    color: #ffffff;
    vertical-align: bottom;
    position: relative;
    background-size: cover;
    background-position: center;

    > div {
      width: inherit;
      height: inherit;
      background-color: rgba(0, 0, 0, 0.2);
      display: flex;
    }

    p {
      width: inherit;
      padding: 0 10px;
      margin: auto;
    }
  }
}

.referral-dashboard-page {
  .card {
    h2 {
      font-size: 24px;
      margin-bottom: 0;
    }
  }

  #summary {
    .flex-row {
      justify-content: space-around;
    }

    .big-value {
      font-size: 60px;
      font-weight: bold;
    }
  }

  .card-header {
    cursor: pointer;
    padding-right: 50px;

    .fa-minus,
    .fa-plus {
      position: absolute;
      right: 20px;
      top: 12px;
      font-size: 24px;
    }
    .fa-minus {
      display: block;
    }

    .fa-plus {
      display: none;
    }

    &.collapsed {
      .fa-minus {
        display: none;
      }

      .fa-plus {
        display: block;
      }
    }
  }

  #share-it {
    @include media-breakpoint-up(md) {
      .card-body {
        margin: auto;
        width: 600px;
      }
    }
  }

  #how-it-works-content {
    i {
      font-size: 80px;
      margin-bottom: 30px;
      margin-top: 30px;
    }
  }

  #referral-url-copy-button {
    cursor: pointer;
    min-width: 230px;
  }

  #referral-url-field,
  #referral-url-field-mobile {
    background-color: white;
  }
}

.referral-show-reward {
  .qr-code {
    svg {
      width: 100%;
    }
  }
}
