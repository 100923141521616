.basicMap {
  .leaflet-container {
    width: 100%;
    height: 300px;
  }

  .leaflet-tooltip {
    width: 170px;
    white-space: normal;

    b {
      font-size: 16px;
    }
  }
}
