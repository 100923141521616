//$header-height: 89px;
$header-height: 64px;

header {
  min-height: $header-height;
}

#header {
  min-height: $header-height;
  //padding-top: 30px;
  padding-top: 5px;

  &.bg-light {
    background-color: white !important;
  }
  .navbar-brand svg {
    width: 150px;
  }

  .header-partner {
    display: flex;
    align-items: center;
    text-decoration: none;

    img {
      margin-right: 10px;
    }

    p {
      display: contents;
      color: $color-mss-blue;
      font-weight: bold;
      font-family: $font-secondary;
    }
  }

  .search-link {
    background-color: $color-mss-blue;
    color: #ffffff !important;
    border-radius: 20px;
    padding-right: 20px;
    padding-left: 20px;
  }

  @media (min-width: 992px) {
    .search-link {
      margin-left: 0.5rem;
    }
  }

  .switch-site-banner {
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 25px;
    background-color: $color-mss-blue;
    color: #fff;
    text-align: center;
    font-size: 13px;
    padding-top: 2px;
    cursor: pointer;
    text-decoration: none;
    z-index: 1;
    overflow: hidden;

    &:hover {
      color: #fff;
    }
  }
}

.navbar-expand-lg .navbar-collapse {
  @include media-breakpoint-up(sm) {
    justify-content: flex-end;
    font-family: $font-secondary;
    font-weight: bold;

    .nav-item.nav-link {
      color: $color-mss-blue !important;
    }
  }
}
