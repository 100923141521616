body {
  color: $color-main-text;
  font-family: $font-main;
  background-color: #f2f1f8;
}

.flash-message {
  position: fixed;
  width: calc(100% - 40px);
  bottom: 0;
  margin: 0 20px 20px 20px;
  right: 0;
  z-index: 100000;
}

.menu-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  .menu-header-left {
  }

  .menu-header-right {
  }
}
