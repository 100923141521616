.session-page {
  background-color: #eeeeee;
  min-height: calc(100vh - #{$header-height});

  .card-header {
    color: $color-mss-blue;
    cursor: pointer;
    font-weight: bold;

    .fa-plus,
    .fa-minus {
      float: right;
      margin-top: 5px;
      color: $mss-secondary-color;
    }
  }

  .return-btn {
    position: absolute;
    z-index: 1;
    top: 10px;
    left: 10px;
    color: white;
    border: 2px solid;
    border-radius: 5px;
    padding: 0 6px;

    @include media-breakpoint-up(md) {
      padding: 3px 15px;
      top: 20px;
      left: 20px;
    }

    i {
      margin-right: 10px;
    }
  }

  .loader {
    margin-top: 100px;
  }

  .head {
    min-height: 160px;
    position: relative;
    margin-bottom: 35px;
    padding: 0;

    .head-content {
      text-align: center;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        90deg,
        #fcdd04 0,
        #f8aa0a 5%,
        #f47e10 20%,
        #ed2c1a 50%,
        #ed2c1a
      );
      color: white;
      padding-top: 40px;
      padding-bottom: 25px;

      h1 {
        font-size: 35px;
      }

      h2 {
        font-size: 20px;
      }

      h3 {
      }

      a {
        color: inherit;
      }
    }

    .booking-cta {
      position: absolute;
      bottom: -20px;
      width: 100%;
      text-align: center;

      .btn {
        margin: auto;
        background-color: $mss-primary-color;
        border-color: #ffffff;
        border-width: 3px;
      }
    }
  }

  .price-block {
    margin-bottom: 30px;

    h3 {
      color: $color-mss-blue;
      font-size: 20px;
      margin-bottom: 10px;
      padding-right: 40px;
    }

    img {
      flex-shrink: 0;
    }

    .price {
      font-size: 40px;
      color: $mss-secondary-color;
      font-family: $font-special-secondary;
      float: right;
      line-height: 30px;
      margin-right: -10px;
      margin-bottom: -10px;
    }

    .d-md-flex {
      > div.img-container {
        flex-grow: 1;
        min-width: 255px;
        max-width: 450px;
        min-height: 170px;
        background-size: cover;
        background-position: center;
      }

      > div.content-container {
        flex-grow: 1;
        padding-left: 20px;
        position: relative;

        .map-maker {
          height: 30px;
          margin-left: -5px;
          margin-top: -5px;
        }

        @include media-breakpoint-down(sm) {
          margin-top: 20px;
          padding-left: 0;
        }
      }
    }
    .available-seats {
      color: #bbbbbb;
      font-weight: bold;

      @-webkit-keyframes clignote-warning {
        0% {
          color: #28a745;
        }
        50% {
          color: #26fe00;
        }
        100% {
          color: #28a745;
        }
      }

      i.fa-exclamation-circle.text-clignote-warning {
        -webkit-animation-name: clignote-warning;
        -webkit-animation-duration: 1s;
        -webkit-animation-iteration-count: infinite;
      }
    }

    img {
      display: inline;
      position: inherit;
      vertical-align: top;
    }

    i:not(.fa-star) {
      margin-right: 10px;
      color: $mss-secondary-color;
      font-size: 20px;
      width: 20px;
      text-align: center;
    }

    p {
      margin-bottom: 5px;
    }

    img.img-session {
      margin: auto;
      margin-bottom: 20px;
      display: block;
    }

    .favorite-button {
      position: absolute;
      right: -5px;
      top: 0;

      i {
        margin-right: 0;
      }
    }

    @include media-breakpoint-up(md) {
      .last-col {
        margin-left: 30px;
        margin-top: 0;
      }

      img.img-session {
        margin-left: 0;
        margin-top: 0;
        margin-bottom: 0;
        margin-right: 20px;
      }
    }
  }

  .accordion-details {
    margin-bottom: 30px;
  }

  .custom-details {
    margin-bottom: 30px;

    p {
      i {
        margin-right: 15px;
      }
    }

    .booking-cta {
      text-align: center;
      .btn {
        background-color: $mss-secondary-color;
        border-color: $mss-secondary-color;
      }
    }
  }

  .place-details {
    margin-bottom: 30px;

    .map-with-one-pin {
      width: 100%;
      height: 200px;
    }

    .favorite-button {
      position: absolute;
      top: 5px;
      right: 10px;
      font-size: 25px;
    }

    p {
      margin-top: 20px;
      margin-bottom: 0;

      i {
        color: $mss-secondary-color;
        padding-right: 10px;
        font-size: 20px;
      }
    }
  }
}
