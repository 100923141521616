.organization-page-header {
  position: relative;
  background-blend-mode: saturation;
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;

  &::before {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #2656ffd9;
    content: "";
  }

  .booking-subtitle {
    text-align: center;
    font-family: $font-secondary;
    font-size: 22px;
  }

  .search-app-wrapper {
    max-width: 700px;
    margin: auto;
    background: #ffffff99;
    padding: 20px 10px;
    border-radius: 15px;
  }

  img {
    max-width: 100%;
    max-height: 150px;
  }
}

.organization-page {
  position: relative;
  background-blend-mode: saturation;
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;

  &::before {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #2656ffd9;
    content: "";
  }

  .main-card {
    display: flex;

    .main-img {
      width: calc(100% + 30px) !important;
      margin-top: -15px;
      margin-left: -15px;
    }
  }

  .card-header {
    font-weight: bold;
  }

  .accordion-detail {
    .card-header {
      cursor: pointer;
      padding-right: 50px;
    }

    .fa-minus,
    .fa-plus {
      position: absolute;
      right: 20px;
      top: 12px;
    }
    .fa-minus {
      display: block;
    }

    .fa-plus {
      display: none;
    }

    .collapsed {
      .fa-minus {
        display: none;
      }

      .fa-plus {
        display: block;
      }
    }
  }
  .search-form-app {
    background-color: #eee;
    max-width: 700px;
    padding: 16px;
    border-radius: 20px;
  }

  .sport-tag {
    background-color: #ed2c1a;
    color: white;
    padding: 3px 10px;
    margin: 3px 3px;
    border-radius: 30px;
    font-size: 15px;
    white-space: nowrap;
    display: inline-block;
  }

  .supervised-organization-tag {
    background-color: $color-mss-blue;
    color: white;
    padding: 3px 10px;
    margin: 3px 3px;
    border-radius: 30px;
    font-size: 15px;
    white-space: nowrap;
    display: inline-block;
  }

  .videoWrapper {
    background: #fff;
    padding: 5px;
    border-radius: 5px;

    > div {
      position: relative;
      padding-bottom: 56.25%; /* 16:9 */
      padding-top: 25px;
      height: 0;

      > iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  .main-carousel {
    width: calc(100% + 30px) !important;
    margin-top: -15px;
    margin-left: -15px;

    img {
      width: 100%;
    }
  }
  .about-block {
    i {
      width: 25px;
      font-size: 25px;
    }

    i.fa-exclamation-circle {
      font-size: 20px;
    }

    .favorite-button-wrapper {
      display: inline;
      div {
        display: inline;
      }

      .favorite-button {
        font-size: 10px;
      }

      .fas,
      .far {
        font-size: 20px;
        width: 25px;
      }
    }
  }

  .search-form-app {
    text-align: center;
    .place-autocomplete {
      width: 100% !important;
      padding-bottom: 10px !important;
      padding-right: 0 !important;
      min-width: 200px;
    }

    .sport-field {
      width: 100% !important;
      padding-bottom: 10px !important;
      padding-right: 0 !important;
      max-width: inherit;
    }

    .date-field {
      width: 100% !important;
      max-width: 100% !important;
      padding-right: 0 !important;
    }

    .btn {
      width: 100% !important;
      margin-top: 10px !important;
    }
  }

  .basicMap {
    width: calc(100% + 30px);
    margin-top: -15px;
    margin-left: -15px;
  }
}

.organization-list {
  .card {
    width: 100%;
  }

  .search-form-app {
    margin: 20px auto;
    background-color: #ffffff99;
    padding: 10px 20px;
    border-radius: 20px;
    width: fit-content;
  }
}

.organization-page-header {
  .favorite-button-wrapper {
    display: inline;
    margin-left: 10px;

    div {
      display: inline;
    }
  }
}
