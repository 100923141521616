.search-app {
  .loader {
    margin-top: 100px;
  }
  .search-menu {
    padding: 15px 0;

    background: linear-gradient(
      90deg,
      #fcdd04 0,
      #f8aa0a 5%,
      #f47e10 20%,
      #ed2c1a 50%,
      #ed2c1a
    );

    .place-autocomplete,
    .sport-field,
    .organization-field {
      width: 100%;
      padding-bottom: 10px;

      .form-control {
        background-color: #ffffff;
        border-right: 0;
      }

      .input-group-text {
        background-color: #ffffff;
        border-left: 0;
        cursor: pointer;

        i {
          font-size: 14px;
        }
      }

      .rw-popup-container {
        width: max-content;
        right: inherit;
      }
    }

    .date-field {
      width: 100%;
    }

    .time-field {
      width: 50%;
    }

    .btn-primary {
      border: solid 2px white;
      margin-left: 15px;
    }

    @include media-breakpoint-up(sm) {
      .place-autocomplete {
        max-width: 350px;
      }

      .place-autocomplete,
      .sport-field,
      .organization-field {
        width: 50%;
        padding-bottom: 0;
      }

      .place-autocomplete {
        padding-right: 15px;
        .spinner-grow {
          margin-right: 15px;
        }
      }

      .sport-field {
        padding-right: 15px;
      }

      .date-field {
        max-width: 170px;
      }

      .time-field {
        max-width: 190px;
      }
    }

    @include media-breakpoint-up(md) {
      .place-autocomplete {
        max-width: calc(75% - 360px);
      }

      height: $search-menu-height;
      .sport-field,
      .organization-field {
        width: 25%;
        max-width: 270px;
        padding-right: 15px;
        padding-bottom: 0;
      }
    }

    @include media-breakpoint-up(lg) {
      .place-autocomplete {
        max-width: 350px;
      }
    }
  }

  .session-block-no-result {
    text-align: center;
    width: 100%;
    margin-top: 60px;
  }


  .search-content {
    height: calc(100vh - #{$header-height});

    .toggle-mobile-map-cta {
      display: block;
      background-color: $mss-secondary-color;
      color: white;
      text-align: center;
      position: fixed;
      bottom: 0;
      width: 100%;
      height: 45px;
      cursor: pointer;
      z-index: 10000;

      .col-12, .col-6 {
        padding-top: 10px;
      }

      .filters-button {
        border-left: solid 2px white;
      }
    }

    .search-result {
      background-color: #eeeeee;
      .row {
        margin: 0;
      }

      .day-separator {
        width: 100%;
        height: 21px;
        text-align: center;
        position: relative;
        margin: 7px 0;

        p {
          position: absolute;
          margin-left: auto;
          margin-right: auto;
          left: 0;
          right: 0;

          span {
            padding: 0 25px;
            background-color: #eeeeee;
            font-size: 15px;
            font-weight: bold;
          }
        }

        .day-separator-bar {
          width: 100%;
          height: 3px;
          background-color: #444444;
          position: absolute;
          top: 9px;
        }
      }
    }

    .search-map {
      padding: 0;

      .leaflet-container {
        width: 100%;
        height: calc(100vh - #{$header-height});
      }

      .mainMarker {
        z-index: 1000 !important;
      }
    }

    @include media-breakpoint-up(md) {
      height: calc(100vh - #{$search-menu-height} - #{$header-height});

      .search-result {
        height: inherit;
        overflow-y: scroll;
      }

      .search-map {
        padding: 0;
        border-left: solid 2px lightgrey;

        .leaflet-container {
          height: calc(100vh - #{$search-menu-height} - #{$header-height});
        }
      }
    }
  }

  .refine-result-card {
    margin-top: 10%;
    margin-right: 10%;
    margin-left: 10%;
    text-align: center;

    .fa-street-view {
      font-size: 70px;
    }

    .place-autocomplete {
      max-width: 500px;
      margin: auto;
    }
  }

  a.sport-block {
    display: inline-block;
    background-color: grey;
    height: 90px;
    width: 130px;
    margin: 10px;
    color: #ffffff;
    vertical-align: bottom;
    position: relative;
    background-size: cover;
    background-position: center;
    cursor: pointer;

    > div {
      width: 100%;
      height: inherit;
      background-color: rgba(0, 0, 0, 0.2);
      display: flex;
    }

    p {
      width: inherit;
      padding: 0 10px;
      margin: auto;
    }

    @include media-breakpoint-down(xs) {
      width: calc(33% - 8px);
      margin: 4px 3px;
      height: 80px;
    }
  }
  @include media-breakpoint-down(sm) {
    .out-of-bounds-block {
      p {
        margin-top: 10px !important;
        margin-bottom: 10px !important;
      }

      img {
        height: 50px;
        width: 50px;
      }

      .font-size-m {
        font-size: 15px;
      }
    }
  }
}

.session-block {
  padding: 0;

  @include media-breakpoint-up(sm) {
    padding: 0 5px;
  }
}

.session-card {
  max-width: 750px;
  margin: 20px auto 10px;
  min-height: 140px;
  background: #ffffff;
  border-radius: 10px;
  display: flex;
  cursor: pointer;
  text-decoration: none;
  color: $color-main-text;
  position: relative;
  flex-direction: column;

  .map-with-one-pin {
    width: 100%;
    height: 130px;
    margin-bottom: 15px;
  }

  .card-header {
    padding: 10px 15px;
    font-size: 14px;
    font-weight: bold;

    .fas {
      position: absolute;
      right: 15px;
      top: 15px;
    }
  }

  .card-body {
    font-size: 14px;

    p i {
        color: $mss-secondary-color;
        padding-right: 10px;
    }
  }

  &.session-card-vertical {
    .session-card-img-block .img-block {
      width: initial;
      height: 200px;
    }
  }

  &.session-card-hovered {
    box-shadow: #777777 0px 0px 15px 0px
  }
}

.template-card-summary {
  margin: 20px auto 20px auto;
  background: #ffffff;
  border-radius: 10px;
  display: flex;
  cursor: pointer;
  text-decoration: none;
  color: $color-main-text;
  position: relative;
  padding-bottom: 40px;

  max-width: 80%;
}

  .session-card-content {
    padding: 10px;
    padding-top: 20px;
    flex-grow: 1;

    p {
      margin-bottom: 0px;
    }

    i.fa-chevron-right {
      color: white;
      float: right;
      font-size: 15px;
      background: $color-mss-red;;
      width: 20px;
      height: 20px;
      border-radius: 50px;
      text-align: center;
      line-height: 20px;
    }

    .img-block {
      width: 100px;
      height: 65px;
      background-position: center;
      background-size: cover;
      border-radius: 6px;
      margin-right: 5px;
    }
  }

  .session-card-sport {
    position: absolute;
    top: -13px;
    left: 45px;
    background-color: $mss-secondary-color;
    color: white;
    padding: 5px 15px;
    border-radius: 20px;
    font-size: 13px;
  }

  .session-card-top-price {
    position: absolute;
    top: -13px;
    right: 45px;
    color: $mss-secondary-color;
    border: solid 2px $mss-secondary-color;
    background-color: white;
    padding: 5px 15px;
    border-radius: 20px;
    font-size: 20px;
    line-height: 13px;
    font-family: $font-special-secondary;
  }

  .session-card-title {
    font-size: 15px;
    font-weight: bold;
  }

  .session-card-subtitle {
    font-size: 13px;
    font-weight: initial;
  }

  .session-distance {
    font-size: 12px;
    color: grey;
    font-weight: normal;
    margin-left: 10px;
  }

  .session-card-price {
    color: $mss-secondary-color;
    font-weight: bold;
    font-size: 25px;
    font-family: $font-special-secondary;
  }

  .session-card-cta {
    font-size: 14px;
  }

  .session-card-img-block {
    .img-block {
      width: 195px;
      height: 115px;
      background-position: center;
      background-size: cover;
      margin-top: 25px;
      margin-left: 10px;
      margin-right: 10px;
      border-radius: 6px;
    }

    .available-seats {
      margin-top: 10px;
      margin-left: 10px;
      width: 100px;
      color: grey;
      background-color: white;
      border: solid #28a745 1px;
      font-size: 11px;
      border-radius: 5px;
      padding: 4px 2px;
      line-height: 11px;
      text-align: center;
      font-weight: bold;
      &.available-seats-full {
        border: solid $mss-secondary-color 1px;
      }
      &.available-seats-infos {
        border: solid grey 1px;

        .fa-circle {
          color: gray !important;
        }
      }

      @-webkit-keyframes clignote-warning {
        0% {
          color: #28a745;
        }
        50% {
          color: #26fe00;
        }
        100% {
          color: #28a745;
        }
      }

      i.fa-circle.text-clignote-warning {
        -webkit-animation-name: clignote-warning;
        -webkit-animation-duration: 1s;
        -webkit-animation-iteration-count: infinite;
      }
    }
  }

  .favorite-button {
    position: absolute;
    right: 10px;
    top: 1px;
    font-size: 25px;
  }

.session-modal {
  padding-bottom: 50px;

  .modal-body .session-card {
    border: solid 1px #ccc;
  }

  .place-about-block {
    i {
      width: 25px;
      font-size: 25px;
    }

    i.fa-exclamation-circle {
      font-size: 20px;
    }
  }
}

.template-card-today-availabilities {
  text-align: center;
  font-size: 10px;
  line-height: 10px;
  position: relative;
  width: fit-content;

  .template-card-availabilities-overlay {
    display: flex;
    background-color: #ffffffaa;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    justify-content: center;
    align-items: center;

    .badge {
      font-size: 12px;
      color: #6c757d;
      background-color: #fff;
      padding: 7px;
      border: solid 2px #6c757d;
    }
  }
}

.template-card-availabilities {
  text-align: center;
  font-size: 10px;
  line-height: 10px;
  position: relative;
  width: fit-content;

  table .badge {
    width: 50px;
    height: 14px;
    padding-top: 4px;
  }

  .badge-success {
    background-color: #3470ff;
  }

  .badge-danger {
    background-color: #ff8a8a;
  }

  .badge-secondary {
    background-color: #e3e3e3;
  }

  .template-card-availabilities-overlay {
    display: flex;
    background-color: #ffffffaa;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    justify-content: center;
    align-items: center;

    .badge {
      font-size: 12px;
      color: #6c757d;
      background-color: #fff;
      padding: 7px;
      border: solid 2px #6c757d;
    }
  }
}

.sessions-view {
  .card-header {
    cursor: pointer;
    .fas {
      position: absolute;
      right: 15px;
      top: 15px;
    }
  }
}

.sessions-view-session-card {
  position: relative;
  .sessions-view-session-card-remaining-seats {
    position: absolute;
    top: -7px;
    right: -10px;
    background: white;
    color: #486bff;
    border: solid 2px #486bff;
    border-radius: 50%;
    font-size: 10px;
    font-weight: bold;
    width: 20px;
    height: 20px;
  }
}