.dashboard-menu {
  a {
    display: inline-block;
    border: solid 3px;
    border-radius: 15px;
    padding: 15px;
    margin-bottom: 10px;
    text-align: center;
    border-color: $color-mss-red;
    color: $color-mss-red;
    font-weight: bold;
    width: 100%;

    &:hover {
      background-color: #ed7b1a1c;
    }

    i {
      font-size: 60px;
      margin-bottom: 20px;
      margin-top: 10px;
    }
  }
}
