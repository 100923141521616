#contact-form {
  max-width: 700px;
  margin: auto;

  .g-recaptcha > div {
    margin: auto;
  }

  .cgu-container {
    margin-bottom: 30px;
    .form-group {
      margin-bottom: 0;
    }
  }
}
