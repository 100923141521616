.sport-page {
  .search-form-app {
    background-color: #eee;
    max-width: 700px;
    padding: 16px;
    border-radius: 20px;
  }

  .sport-tag {
    background-color: $color-mss-red;
    color: white;
    padding: 10px 15px;
    margin: 5px 15px;
    border-radius: 30px;
    font-size: 20px;
    white-space: nowrap;
  }

  .sport-field {
    text-align: center;
  }

  img {
    width: 100%;
  }
}

.sport-page-header {
  position: relative;
  background-blend-mode: saturation;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  &::before {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #2656ffd9;
    content: "";
  }
}

.sport-list {
  .sports-wrapper {
    @include media-breakpoint-down(xs) {
      width: calc(100% + 32px);
      position: relative;
      left: -16px;
    }
  }

  a.sport-block {
    display: inline-block;
    background-color: grey;
    height: 90px;
    width: 130px;
    margin: 10px;
    color: #ffffff;
    vertical-align: bottom;
    position: relative;
    background-size: cover;
    background-position: center;

    > div {
      width: 100%;
      height: inherit;
      background-color: rgba(0, 0, 0, 0.2);
      display: flex;
    }

    p {
      width: inherit;
      padding: 0 10px;
      margin: auto;
    }

    @include media-breakpoint-down(xs) {
      width: calc(33% - 8px);
      margin: 4px 3px;
      height: 80px;
    }
  }
}
