@import "../variables";

$color-occitanie-yellow: #15a7ff;
$color-occitanie-red: rgba(31, 56, 91, 0.92);

$mss-primary-color: $color-occitanie-red;
$mss-secondary-color: $color-occitanie-yellow;

@import "../all";

.background-header-blue {
  background-color: $color-occitanie-red;
  background-image: none;
}

#header .search-link,
.search-form-app .btn,
.search-form-app .btn:active {
  background-color: $color-occitanie-yellow;
  border-color: $color-occitanie-yellow;
  color: black !important;
}

.session-block .session-card .session-card-sport {
  background-color: $color-occitanie-red;
}

.session-block .session-card .session-card-price {
  color: $color-occitanie-red;
}
.background-mss-gradient,
.search-app .search-menu,
.session-page .head .head-content {
  background: linear-gradient(
    90deg,
    $color-occitanie-red 0,
    $color-occitanie-yellow
  );
}

.homepage .tags-list > p, .homepage .tags-list > a {
  background-color: $color-occitanie-yellow;
  color: black;
}

footer {
  background-color: #0977ba;
}

.homepage-header {
  position: relative;
}

.homepage-header:before {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(31, 56, 91, 0.70);
  content: "";
}

.organization-list {
  .btn.btn-danger {
    background-color: $color-occitanie-red;
    border-color: $color-occitanie-red;
  }
}

.homepage-header .tags-list>p, .homepage-header .tags-list>a {
  display: inline-block;
  background-color: rgba(255,255,255,.5);
  padding: 10px 20px;
  border-radius: 43px;
  font-weight: 700;
}

.homepage-header .search-form-app {
  max-width: 850px;
  margin: auto;
  background-color: rgba(255,255,255,.6);
  padding: 16px;
  border-radius: 20px;
}

.navbar-brand {
  display: flex;
  align-items: center;
  text-decoration: none;

  img {
    margin-right: 10px;
  }

  p {
    display: contents;
    color: $color-mss-blue;
    font-weight: bold;
    font-family: $font-secondary;
  }
}

.homepage-top-row {
  .btn-primary {
    background-color: $color-occitanie-yellow;
    border-color: $color-occitanie-yellow;
  }
}