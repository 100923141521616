footer {
  background-position: 121% 63%;
  background-size: 45%;
  color: #ffffff;
  background-color: #140d4f;
  background-image: url(../../images/content/pinson.png);
  background-repeat: no-repeat;

  a,
  a:hover {
    color: #ffffff;
  }

  .sport-list {
    > a {
      width: calc(33% - 10px);
      display: inline-block;
      font-size: 12px;
      padding-right: 10px;
      line-height: 10px;
      height: 10px;
      overflow: hidden;
    }
  }

  i.fab {
    font-size: 35px;
    margin-right: 15px;
  }

  hr {
    border-top: 1px solid rgba(255, 255, 255, 0.5);
  }
}
