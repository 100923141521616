.loader {
  height: 100%;
  max-height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    text-align: center;
  }

  .spinner-border {
    height: 100px;
    width: 100px;
    border: 0.4em solid currentColor;
    border-right-color: transparent;
    margin-bottom: 25px;
  }
}
