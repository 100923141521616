.booking-modal {
  .wording-line1 {
    margin-bottom: 20px;
    color: $color-mss-red;
    text-align: center;
    font-size: 25px;
    font-weight: bold;
    margin-top: 10px;
    font-family: $font-secondary;
  }

  .wording-line2 {
    margin-bottom: 40px;
    color: $color-mss-blue;
    text-align: center;
    font-size: 25px;
    font-weight: bold;
    font-family: $font-secondary;
  }

  .wording-line3 {
    margin-bottom: 40px;
    color: $color-mss-blue;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    font-family: $font-secondary;
  }

  .success-icon {
    text-align: center;
    color: green;
    font-size: 90px;
  }

  .cancellation-policy {
    font-size: 13px;
    margin-top: 20px;
    color: gray;
  }

  .numberOfUnitsSelect {
    margin-top: 40px;
    margin-bottom: 40px;

    label {
      display: block;
      text-align: center;
      color: $color-mss-blue;
      font-size: 25px;
      font-weight: bold;
      font-family: $font-secondary;
    }

    select {
      width: 80px;
      margin: auto;
      text-align-last: center;
      text-align: center;
      -ms-text-align-last: center;
      -moz-text-align-last: center;
    }

    button {
      margin-top: 40px;
    }
  }
  .recaptcha-wrapper {
    margin-bottom: 30px;

    > div > div > div {
      margin: auto;
    }
  }
}
