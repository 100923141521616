#newsletterConvidAccordion,
#newsFeedModalAccordion {
  position: fixed;
  bottom: 30px;
  left: 30px;
  width: 450px;
  -webkit-box-shadow: 10px 10px 37px 5px rgba(0, 0, 0, 0.31);
  -moz-box-shadow: 10px 10px 37px 5px rgba(0, 0, 0, 0.31);
  box-shadow: 10px 10px 37px 5px rgba(0, 0, 0, 0.31);

  .card {
    border: 0;
  }

  .card-header {
    background-color: #f9cc17;
    padding: 0;
  }

  button {
    font-size: 15px;
    font-weight: bold;
    text-decoration: none;
  }

  .card-body {
    font-size: 12px;
    max-height: calc(100vh - 150px);
    overflow-x: scroll;
  }

  .remove-banner {
    position: absolute;
    right: 15px;
    top: 10px;
  }

  .open-collapse,
  .close-collapse {
    margin-left: 10px;
  }

  .open-collapse {
    display: inline-block;
  }
  .close-collapse {
    display: none;
  }

  .collapsed {
    .open-collapse {
      display: none;
    }
    .close-collapse {
      display: inline-block;
    }
  }
}

#newsletterConvidMobileButton,
#newsFeedModalMobileButton {
  background-color: #f9cc17;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px 10px 10px 35px;
  font-size: 14px;
  line-height: 16px;

  .fas.fa-chevron-up {
    position: absolute;
    right: 15px;
    top: 10px;
  }

  .remove-banner {
    position: absolute;
    left: 10px;
    top: calc(50% - 14px);
    font-size: 25px;
  }
}
