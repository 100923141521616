#addToCalendarModal,
.addToCalendarModal {
  .modal-body {
    h5 {
      margin: 30px 0;
    }
  }
  .btn-outlook,
  .btn-outlook:hover {
    color: #fff;
    background-color: #1089f5 !important;
    border-color: #1089f5 !important;
  }
  .btn-ical,
  .btn-ical:hover {
    color: #fff;
    background-color: #e35958 !important;
    border-color: #e35958 !important;
  }
  .btn-google-calendar,
  .btn-google-calendar:hover {
    color: #fff;
    background-color: #f2b622 !important;
    border-color: #f2b622 !important;
  }
}
