#accordionFAQ {
  .card-header {
    background: #ffffff;
    h2 {
      font-family: $font-special;

      .btn-link {
        color: $color-electric-blue-dark;
        font-size: 24px;
        text-align: left;
      }
    }
  }
}
