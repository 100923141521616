.organization-stats-table {
  .background-orange {
    background-color: #ffd166;
  }
  .background-orange-light {
    background-color: #fff3e2;
  }
  .background-blue {
    background-color: #118ab2;
  }
  .background-blue-light {
    background-color: #c3f0ff;
  }
  .background-green {
    background-color: #06d6a0;
  }
  .background-green-light {
    background-color: #befff0;
  }
  .background-grey {
    background-color: #aaaaaa;
  }
  .background-grey-light {
    background-color: #eeeeee;
  }
  .background-purple {
    background-color: #f2621b;
  }
  .background-purple-light {
    background-color: #ffc9ae;
  }
  .big-right-border {
    border-right-width: 15px;
  }

  th,
  td {
    vertical-align: middle !important;
    text-align: center !important;
  }
}
