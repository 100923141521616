.color-electric-blue {
  color: $color-electric-blue-dark;
}

footer {
  background-color: $color-electric-blue;
}

.dashboard-menu a {
  color: $color-mss-blue;
  border-color: $color-mss-blue;

  &:hover {
    background-color: rgba(77, 113, 255, 0.1);
  }
}

.background-mss-gradient {
  background: linear-gradient(90deg, #75b4ff 0, #0926a6 70%, #140c4f);
}

#header {
  .search-link {
    background-color: $color-mss-red;
  }
}

.navbar-brand {
  padding-top: 0;
  padding-bottom: 0;
}

.search-app .search-menu {
  background: linear-gradient(90deg, #75b4ff 0, #0926a6 70%, #140c4f);
}

.session-page .head .head-content {
  background: linear-gradient(90deg, #75b4ff 0, #0926a6 70%, #140c4f);
}

.session-page {
  .booking-cta {
    .btn {
      background-color: $color-mss-red !important;
      border-color: $color-mss-red !important;
    }
  }
}

.session-page .head .booking-cta .btn {
  border-color: #ffffff !important;
}

.homepage {
  .section-why-use-mss {
    .background {
      background-image: url(../images/homepage-online/visuel-pq-def.png);
    }

    .btn-danger {
      background-color: $color-mss-red;
      border-color: $color-mss-red;
    }
  }
}

.search-form-app {
  .btn,
  .btn:active {
    background-color: $color-mss-red;
    border-color: $color-mss-red;
  }
}

.breadcrumb {
  background-color: transparent;
  padding: 0;

  a,
  li,
  li.active {
    color: #ffffff;
    font-size: 14px;
  }

  .breadcrumb-item + .breadcrumb-item::before {
    color: #ffffff;
  }
}

.phoneNumberField {
  select {
    width: 150px;
    max-width: 200px;
  }

  input {
    width: calc(100% - 150px);
    flex-grow: 1;
  }
}

.court-view {
  .court-view-title {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    margin-top: 20px;
  }
}

.courts-slider {
  margin: 0 -20px;
  width: calc(100% + 40px);
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;

  .courts-slider-slider {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;

    // Hack to put scrollbar to the top
    transform: rotateX(180deg);

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
    }

    &::-webkit-scrollbar-thumb {
      background: $color-mss-blue;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: $color-mss-blue;
    }

    .court-card {
      // Hack to put scrollbar to the top
      transform: rotateX(180deg);
      flex: 0 0 auto;
      margin: 3px;
      padding: 10px 10px 30px 10px;
      background-color: #fff;
      border-radius: 20px;
      width: 12.5vw;
      min-width: 80px;
      max-width: 250px;
      position: relative;
      text-align: center;

      .spinner-grow {
        position: absolute;
        right: 5px;
        top: 5px;
      }

      .court-card-title {
        text-align: center;
        font-weight: bold;
        line-height: 13px;
        font-size: 15px;
        display: table-cell;
        vertical-align: middle;
        height: 20px;
        width: 250px;
      }

      hr {
        position: absolute;
        right: 0;
        left: 0;
        margin: 0;
      }

      &.court-card-disabled {
        background-color: #f9f9f9;
      }
    }
  }

  .court-timeline {
    flex: 0 0 auto;
    margin: 20px 3px 3px 3px;
    background-color: #fff;
    border-radius: 20px;
    width: 30px;
    position: relative;
    font-size: 13px;

    .court-timeline-time {
      color: grey;
      margin-top: -10px;
      right: 0;
      left: 0;
      text-align: center;
      position: absolute;
    }
  }
}

.session-card-for-court {
  position: absolute;
  right: 3px;
  left: 3px;
  display: flex;
  border-radius: 10px;
  padding: 5px;
  margin-bottom: 10px;
  align-items: center;
  justify-content: center;
  text-decoration: none !important;
  border: solid 1px white;

  p {
    color: white;
    font-weight: bold;
    margin: 0;
    font-size: 12px;
  }
}

.activity-templates-grid {
  .activity-templates-grid-title {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    margin-top: 20px;
  }
}

.color-yellow {
  color: #f9bc07 !important;
}