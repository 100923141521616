$color-mss-red: #ed2c1a;
$color-mss-blue: #140c4f;
$color-main-text: #444444;
$color-light-text: #797891;
$color-facebook-blue: #3b5998;
$color-google-red: #db4a39;

$font-main: Roboto, sans-serif;
$font-secondary: Quicksand, sans-serif;
$font-special: "Permanent Marker", cursive;
$font-special-secondary: "Caveat Brush", cursive;

$header-height: 62px;
$search-menu-height: 68px;

$mss-primary-color: $color-mss-blue;
$mss-secondary-color: $color-mss-red;

$color-electric-blue: #486bff;
$color-electric-blue-dark: #254edd;
