.color-blue {
  color: $color-mss-blue;
}
.color-red {
  color: $color-mss-red;
}
.color-light-text {
  color: $color-light-text;
}

.font-secondary {
  font-family: $font-secondary;
}
.font-special {
  font-family: $font-special;
}
.font-special-secondary {
  font-family: $font-special-secondary;
}

.bold {
  font-weight: bold;
}
.lighter {
  font-weight: lighter;
}
.underline {
  text-decoration: underline;
}

.font-size-xs {
  font-size: 12px;
}
.font-size-s {
  font-size: 14px;
}
.font-size-m {
  font-size: 18px;
}
.font-size-l {
  font-size: 22px;
}
.font-size-xl {
  font-size: 32px;
}

@include media-breakpoint-up(sm) {
  .font-size-xs {
    font-size: 12px;
  }
  .font-size-s {
    font-size: 14px;
  }
  .font-size-m {
    font-size: 20px;
  }
  .font-size-l {
    font-size: 28px;
  }
  .font-size-xl {
    font-size: 40px;
  }
}

.background-color-white {
  background-color: #ffffff;
}
.background-mss-gradient {
  background: linear-gradient(
    90deg,
    #fcdd04 0,
    #f8aa0a 5%,
    #f47e10 20%,
    #ed2c1a 50%,
    #ed2c1a
  );
}
.background-header-orange {
  background-image: url(../../images/content/header-home4.jpg);
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
}

.background-header-blue {
  background-image: url(../../images/content/header-home4-blue.jpg);
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
}

.background-logo {
  background-image: url(../../images/content/bg-logo.png);
  background-repeat: no-repeat;
  background-position: left bottom;
  background-color: white;
}

.rw-list {
  max-height: 350px;
}

.cc-revoke {
  display: none !important;
}

.card-header {
  h1 {
    font-size: 24px;
    margin-bottom: 0;
  }
}

.custom-file-input ~ .custom-file-label::after {
  content: "Parcourir...";
}

.anchor {
  display: block;
  position: relative;
  top: -120px;
  visibility: hidden;
}

.impersonationSwitch,
.impersonationSwitch:hover {
  position: fixed;
  bottom: 10px;
  right: 10px;
  background-color: $color-mss-red;
  padding: 10px 40px;
  border-radius: 5px;
  color: white;
  z-index: 1000;
  text-decoration: none;
}

.cursor-pointer {
  cursor: pointer;
}