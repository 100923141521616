.favorite-button {
  cursor: pointer;
  font-size: 30px;

  &.favorite-button-loading {
    i {
      color: #e5a49e !important;
    }
  }

  &.favorite-button-like {
    i {
      color: $color-mss-red !important;
    }
  }

  &.favorite-button-notlike {
    i {
      color: #f9bc07 !important;
    }
  }
}

.favorite-page {
  @include media-breakpoint-up(sm) {
    .favorite-organization-block,
    .favorite-session-block {
      border-right: solid 1px lightgray;
    }
  }

  h3 {
    font-size: 24px;
  }

  .favorite-card {
    h5 {
      margin-bottom: 5px;
    }

    img {
      width: 70px;
      height: 47px;
      margin-right: 10px;
      margin-bottom: 10px;
    }

    p {
      font-size: 14px;
      margin-bottom: 5px;
    }
  }
}
