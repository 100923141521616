.registration-form,
.login-form {
  max-width: 450px;
  margin: auto;

  .g-recaptcha {
    > div {
      margin: auto;
    }
  }
}

.btn-outline-facebook {
  color: $color-facebook-blue;
  background-color: #fff;
  border-color: $color-facebook-blue;

  &:hover {
    color: #fff;
    background-color: $color-facebook-blue;
    border-color: $color-facebook-blue;
  }

  i {
    font-size: 24px;
    position: absolute;
    left: 30px;
  }
}

.btn-outline-google {
  color: $color-google-red;
  background-color: #fff;
  border-color: $color-google-red;

  &:hover {
    color: #fff;
    background-color: $color-google-red;
    border-color: $color-google-red;
  }

  i {
    font-size: 24px;
    position: absolute;
    left: 30px;
  }
}
