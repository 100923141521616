.place-autocomplete {
  position: relative;

  .spinner-grow {
    position: absolute;
  }

  .input-group-append {
    .input-group-text {
      background-color: white;
      border-left: none;
      width: 40px;

      .spinner-grow {
        color: #000000;
      }
    }
  }

  .dropdown-menu {
    min-width: 300px;
  }

  .dropdown-item {
    font-size: 14px;
  }

  hr {
    margin: 5px 0;
  }

  .no-result-validator {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 70%;
    color: #ed2c1a;
    position: absolute;
    bottom: -15px;
    left: 0;
    padding-right: 54px;

    @include media-breakpoint-down(xs) {
      bottom: -2px;
      padding-right: 40px;
    }
  }
}

.locationMapPin {
  position: absolute;
  top: calc(50% - 25px);
  right: calc(50% - 14px);
  z-index: 10000;

  i {
    font-size: 50px;
  }
}
